<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @open="getData"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "ZoneFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      properties: {
        gardener: {
          key: "gardener_id",
          label: "Gardener",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        no_of_location_areas: {
          key: "location_areas",
          label: "No. of Location areas",
          type: "list",
          options: [
            {
              label: "0 - 5",
              value: "0-5",
            },
            {
              label: "6 - 10",
              value: "6-10",
            },
            {
              label: "11 - 15",
              value: "11-15",
            },
            {
              label: "16 - 20",
              value: "16-20",
            },
          ],
          value: [],
          searchable: false,
        },
      },
    };
  },
  computed: {
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.getGardeners();
    },
    getGardeners() {
      if (this.properties.gardener.options.length) {
        this.loading = false;
        return;
      }

      this.gardeners.length
        ? this.setGardenerOptions()
        : this.$store.dispatch(actions.GET_GARDENERS_LIST).then(() => {
            this.setGardenerOptions();
          });
    },
    setGardenerOptions() {
      this.properties.gardener.options = this.gardeners.map((gardener) => {
        return {
          label: this.formatName(gardener.name),
          value: gardener.id,
        };
      });
      this.loading = false;
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          switch (type) {
            case "list":
              params.params[key] = value[0];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        } else {
          params.params[key] = "all";
          params.paramsLabel[key] = "All";
        }
      });

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
