var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.searchZones}},[(_vm.allowAccessFor(['superadmin', 'admin', 'operations']))?_c('template',{slot:"actions"},[_c('el-button',{staticClass:"ml-10",attrs:{"type":"primary"},on:{"click":_vm.addMFC}},[_vm._v("Add new MFC")])],1):_vm._e()],2),(_vm.fetching)?_c('eden-loader',{attrs:{"type":"table-with-tabs","tabs":1}}):_c('div',[(_vm.filterParams.status)?_c('eden-filter-items',{attrs:{"params":_vm.filterParams.paramsLabel},on:{"clear-filter":_vm.clearFilter,"clear-filters":_vm.clearFilters}}):_vm._e(),_c('el-table',{attrs:{"data":_vm.pageData,"empty-text":"No zones!"}},[_c('el-table-column',{attrs:{"prop":"zone"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"text-faded"},[_vm._v(_vm._s(scope.row.name || "-"))])]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Name ")])])],2),_c('el-table-column',{attrs:{"prop":"locations","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"text-link text-cursor",on:{"click":function($event){return _vm.showLocationAreas(scope.row)}}},[_vm._v(" "+_vm._s(scope.row.location_areas_count)+" ")])]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"eden-table-header"},[_vm._v(" Location areas "),_c('i',{staticClass:"el-icon-bottom"})])])],2),(_vm.allowAccessFor(['superadmin', 'admin', 'operations']))?_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{staticClass:"more",on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"eden-icon-edit","command":{
                  action: 'edit',
                  row: scope.row,
                  index: scope.$index,
                }}},[_vm._v("Edit zone")]),_c('el-dropdown-item',{attrs:{"icon":"eden-icon-delete","command":{
                  action: 'delete',
                  row: scope.row,
                  index: scope.$index,
                }}},[_vm._v("Delete zone")])],1)],1)]}}],null,false,1378959240)}):_vm._e()],1),(_vm.showPagination)?_c('eden-pagination',{attrs:{"from":_vm.centerFrom,"to":_vm.centerTo,"total":_vm.total,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],1),_c('MFCLocationAreas',{attrs:{"show":_vm.showZoneLocationAreas,"zone":_vm.fulfillmentCenter},on:{"update:show":function($event){_vm.showZoneLocationAreas=$event}}}),_c('MFCForm',{attrs:{"action":_vm.action,"show":_vm.showZoneForm,"zone":_vm.fulfillmentCenter},on:{"update:show":function($event){_vm.showZoneForm=$event},"success":_vm.updateFulfillment}}),_c('MFCDelete',{attrs:{"show":_vm.showZoneDelete,"zone":_vm.fulfillmentCenter},on:{"update:show":function($event){_vm.showZoneDelete=$event},"success":_vm.removeFulfillment}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }