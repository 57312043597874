<template>
  <div>
    <eden-table-actions :title="title" @search="searchZones">
      <template
        slot="actions"
        v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
      >
        <zone-filter :clear="filterParams.clear" @filter="applyFilter" />
        <el-button type="primary" class="ml-10" @click="addZone"
          >Add new Zone</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="fetching" type="table-with-tabs" :tabs="1" />
    <div v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table :data="pageData" empty-text="No zones!">
        <el-table-column prop="zone">
          <template slot="header">
            <span class="eden-table-header"> Zone </span>
          </template>
          <template v-slot="scope">
            <p class="text-faded">{{ scope.row.cluster || "-" }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="locations" width="120">
          <template slot="header">
            <span class="eden-table-header"> Priority </span>
          </template>
          <template v-slot="scope">
            <p class="text-faded">{{ scope.row.priority || "-" }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="locations" width="200">
          <template slot="header">
            <span class="eden-table-header">
              Location areas
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <p
              class="text-link text-cursor"
              @click="showLocationAreas(scope.row)"
            >
              {{ scope.row.location_areas_count }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="customers" width="200">
          <template slot="header">
            <span class="eden-table-header">
              No of Customers
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <p class="text-faded">
              {{ scope.row.total_customers }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="gardener" width="220">
          <template slot="header">
            <span class="eden-table-header">
              Gardener in charge
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <template v-if="scope.row.gardener">
              <el-popover
                v-if="scope.row.gardener.length > 1"
                placement="top-end"
                trigger="hover"
                width="200"
              >
                <ul>
                  <li v-for="(gardener, i) in scope.row.gardener" :key="i">
                    {{ formatName(gardener) }}
                  </li>
                </ul>
                <p slot="reference" class="text-cursor">
                  {{ scope.row.gardener.length }} Gardeners
                </p>
              </el-popover>
              <p v-else class="text-black">
                {{ scope.row.gardener[0] }}
              </p>
            </template>
            <template v-else>
              <p class="text-faded">No gardener assigned.</p>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
          width="60"
        >
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit"
                  :command="{
                    action: 'edit',
                    row: scope.row,
                    index: scope.$index,
                  }"
                  >Edit zone</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    row: scope.row,
                    index: scope.$index,
                  }"
                  >Delete zone</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="showPagination"
        :from="zonesFrom"
        :to="zonesTo"
        :total="zonesTotal"
        :current-page.sync="page"
      />
    </div>
    <zone-location-areas :show.sync="showZoneLocationAreas" :zone="zone" />
    <zone-form
      :action="action"
      :show.sync="showZoneForm"
      :zone="zone"
      @success="updateZone"
    />
    <zone-delete
      :show.sync="showZoneDelete"
      :zone="zone"
      @success="removeZone"
    />
  </div>
</template>

<script>
import ZoneLocationAreas from "@/components/Zones/Zone/ZoneLocationAreas";
import ZoneForm from "@/components/Zones/Zone/ZoneForm";
import ZoneDelete from "@/components/Zones/Zone/ZoneDelete";

import ZoneFilter from "@/components/Zones/Zone/ZoneFilter";

import zones from "@/requests/zones";
import * as actions from "@/store/action-types";

export default {
  name: "Zones",
  components: {
    ZoneLocationAreas,
    ZoneDelete,
    ZoneForm,
    ZoneFilter,
  },
  data() {
    return {
      fetching: false,
      page: 1,
      pageData: [],
      showPagination: false,
      showFilters: false,
      showZoneLocationAreas: false,
      showZoneForm: false,
      showZoneDelete: false,
      zone: {},
      total: "",
      filterItems: {},
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      action: "add",
    };
  },
  computed: {
    zones() {
      return this.$store.getters.zones;
    },
    zonesFrom() {
      return this.zones.pages[this.page].from;
    },
    zonesTo() {
      return this.zones.pages[this.page].to;
    },
    zonesTotal() {
      return this.zones.total;
    },
    title() {
      return `${this.total} Zones`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.zones.pages[this.page];
      if (pageFetched) {
        this.setCurrentPageData();
      } else {
        this.getZones();
      }
    },
  },
  created() {
    const pageFetched = this.$store.getters.zones.pages[this.page];
    if (pageFetched) {
      this.setCurrentPageData();
    } else {
      this.getZones();
    }
  },
  methods: {
    setCurrentPageData() {
      this.pageData = this.zones.pages[this.page].data;
      this.total = this.zonesTotal;
      this.showPagination = true;
      this.showFilters = false;
    },
    addZone() {
      this.showZoneForm = true;
      this.action = "add";
      this.zone = {};
    },
    getZones() {
      this.fetching = true;
      this.$store
        .dispatch(actions.GET_ZONES, this.page)
        .then(() => {
          this.setCurrentPageData();
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchZones(query) {
      if (query === "") {
        this.setCurrentPageData();
        return;
      }
      this.fetching = true;
      zones
        .search(query)
        .then((response) => {
          this.pageData = this.sortList(response.data.data, "cluster");
          this.total = this.pageData.length;
          this.showPagination = false;
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.setCurrentPageData();
        });
    },

    applyFilter({ params, paramsLabel }) {
      this.filterParams = {
        clear: false,
        status: true,
        params: params,
        paramsLabel: paramsLabel,
      };
      this.filterItems = params;
      this.filterZones();
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];
      this.filterParams = {
        clear: false,
        status: true,
        params: this.filterParams.params,
        paramsLabel: this.filterParams.paramsLabel,
      };
      this.applyFilter({
        params: this.filterParams.params,
        paramsLabel: this.filterParams.paramsLabel,
      });
    },
    clearFilters() {
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.setCurrentPageData();
    },
    filterZones() {
      const payload = {
        location_areas: this.filterItems.location_areas
          ? this.filterItems.location_areas
          : "all",
        gardener_id: this.filterItems.gardener_id
          ? this.filterItems.gardener_id
          : "all",
      };
      this.fetching = true;
      zones
        .filter(payload)
        .then((response) => {
          this.pageData = this.sortList(response.data.data, "cluster");
          this.total = this.pageData.length;
          this.showPagination = false;
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.setCurrentPageData();
        });
    },
    showLocationAreas(zone) {
      this.zone = { ...zone };
      this.showZoneLocationAreas = true;
    },
    command(command) {
      this.zone = {};
      this.zone = { ...command.row, index: command.index };
      if (command.action === "delete") {
        this.showZoneDelete = true;
      } else {
        this.showZoneForm = true;
        this.action = "edit";
      }
    },
    updateZone(updatedZone) {
      if (updatedZone) {
        const zone = this.pageData[this.zone.index];
        zone.cluster = updatedZone.cluster_name;
        zone.priority = updatedZone.priority;
        zone.location_areas_count = updatedZone.location_area_id.length;
      } else {
        this.getZones();
      }
    },
    removeZone() {
      this.pageData.splice(this.zone.index, 1);
      this.total -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  color: #2b50ed !important;
}
</style>
