<template>
  <el-dialog
    :title="title"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
    append-to-body
  >
    <el-form :model="form" label-position="top" ref="zoneForm">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="MFC" prop="name">
            <el-input type="text" v-model="form.name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Location areas" prop="location_area_ids">
        <el-select
          v-model="form.location_area_ids"
          filterable
          multiple
          placeholder="Select location areas"
        >
          <el-option
            v-for="(location, index) in locationareas"
            :key="index"
            :label="location.name"
            :value="location.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="adding"
        @click="addMFC"
        >Add</el-button
      >
      <el-button v-else type="primary" :loading="updating" @click="updateZone"
        >Save changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import mfc from "@/requests/zones/mfc";

export default {
  name: "MFCForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    zone: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        location_area_ids: [],
      },
      adding: false,
      updating: false,
    };
  },
  computed: {
    title() {
      return this.action === "add" ? "Add new MFC" : "Edit MFC";
    },
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        console.log(this.zone, "zonnnn")
        this.form.center_id = this.zone.id;
        this.form.name = this.zone.name;
        this.form.location_area_ids = this.zone.location_areas.map(area => area.id) || [];
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      (this.form = {
        name: "",
        priority: "",
        location_area_id: [],
      }),
        this.$refs.zoneForm.resetFields();
    },
    addMFC() {
      console.log(this.form, "jjjj")
      this.$refs.zoneForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        mfc
          .add(this.form)
          .then((response) => {
            if (response.data.status) {
              this.adding = false;
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.adding = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
    updateZone() {
      this.$refs.zoneForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;

        const payload = JSON.parse(JSON.stringify(this.form));
        mfc
          .update(payload)
          .then((response) => {
            if (response.data.status) {
              this.updating = false;
              this.$message.success(response.data.message);
              this.$emit("success", payload);
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.updating = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
