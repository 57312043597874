<template>
  <el-dialog
    :title="title"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-form-item label="Location area" prop="location_area_name">
        <el-input
          type="text"
          v-model="form.location_area_name"
          placeholder="Enter location area name"
        />
      </el-form-item>
      <el-form-item label="Zone" prop="cluster_id">
        <el-select
          v-model.number="form.cluster_id"
          filterable
          placeholder="Select a zone"
          @change="setZoneName"
        >
          <el-option
            v-for="cluster in zones"
            :key="cluster.id"
            :label="cluster.name"
            :value="cluster.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Gardener" prop="gardener_id">
        <el-select
          v-model.number="form.gardener_id"
          filterable
          placeholder="Select a gardener"
          @change="setGardenerName"
        >
          <el-option
            v-for="gardener in gardeners"
            :key="gardener.id"
            :label="gardener.name"
            :value="gardener.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="shouldShow = false">Cancel</el-button>
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="adding"
        @click="addLocationArea"
        >Add</el-button
      >
      <el-button
        v-else
        type="primary"
        :loading="updating"
        @click="updateLocationArea"
        >Save change</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import locationareas from "@/requests/zones/locationareas";
import * as actions from "@/store/action-types";

export default {
  name: "LocationAreaForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    locationarea: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        location_area_name: "",
        cluster_id: null,
        gardener_id: null,
      },
      zoneName: "",
      gardenerName: "",
      adding: false,
      updating: false,
    };
  },
  computed: {
    title() {
      return this.action === "add"
        ? "Add new Location Area"
        : "Edit Location Area";
    },
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    zones() {
      return this.$store.getters.zones_list;
    },
    gardeners() {
      const gardeners = [...new Set(this.$store.getters.gardeners_list)];
      return this.sortList(gardeners, "name");
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        this.form.location_area_name = this.locationarea.location_area;
        this.form.location_area_id = this.locationarea.id;
        this.form.gardener_id =
          this.locationarea.gardener && this.locationarea.gardener.gardener_id;

        if (this.locationarea.cluster) {
          this.setZone();
        }
      }

      if (this.show && !this.gardeners.length) {
        this.$store.dispatch(actions.GET_GARDENERS_LIST);
      }
    },
    gardeners() {
      this.setGardenerName();
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.updating = false;
      this.$refs.form.resetFields();
    },
    setZone() {
      const zoneId = this.zones.filter(
        (cluster) =>
          cluster.name.toLowerCase() === this.locationarea.cluster.toLowerCase()
      );
      if (zoneId.length) {
        this.form.cluster_id = zoneId[0].id;
        this.setZoneName();
      }
    },
    setZoneName() {
      const zoneName = this.zones.filter(
        (zone) => zone.id === this.form.cluster_id
      );
      if (zoneName.length) {
        this.zoneName = zoneName[0].name;
      }
    },
    setGardenerName() {
      const gardenerName = this.gardeners.filter(
        (gardener) => gardener.id === this.form.gardener_id
      );
      if (gardenerName.length) {
        this.gardenerName = gardenerName[0].name;
      }
    },
    addLocationArea() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        locationareas
          .add(this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.adding = false;
              this.$emit("success");
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.adding = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
    updateLocationArea() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        locationareas
          .update(this.form)
          .then((response) => {
            if (response.data.status) {
              this.updating = false;
              this.$message.success(response.data.message);
              this.$emit("success", {
                location_area: this.form.location_area_name,
                cluster: this.zoneName,
                gardener: this.gardenerName,
              });
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.updating = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
