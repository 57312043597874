<template>
  <el-dialog :visible.sync="shouldShow" @close="closeEvent" width="30%">
    <template slot="title">
      <h6 class="font-sm">
        Location Areas in
        {{ zone.cluster }}
      </h6>
    </template>
    <div class="location-areas">
      <ul>
        <li v-for="(area, i) in zone.location_areas" :key="i">
          <p>{{ area }}</p>
        </li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ZoneLocationArea",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    zone: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.location-areas {
  max-height: 60vh;
  overflow-y: scroll;

  li {
    padding: 7px 0;
  }
}
</style>
