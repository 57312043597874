<template>
  <eden-confirm-dialog
    title="Delete Zone"
    button-type="danger"
    button-text="Delete"
    :show.sync="shouldShow"
    @confirm="deleteZone"
  >
    <p>
      Are you sure you want to delete this location area,
      <strong>{{ locationarea.location_area }}</strong
      >?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import locationareas from "@/requests/zones/locationareas";

export default {
  name: "LocationAreaDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    locationarea: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    deleteZone() {
      locationareas
        .delete(this.locationarea.id)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
