<template>
  <div>
    <eden-page-header :title="title" />
    <template>
      <div>
        <el-tabs v-model="tabMenu">
          <el-tab-pane label="Zones" name="zones">
            <zones />
          </el-tab-pane>
          <el-tab-pane label="Location  Areas" name="locationareas">
            <location-areas />
          </el-tab-pane>
          <el-tab-pane label="MFCs" name="mfc">
            <MFCs />
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import Zones from "@/components/Zones/Zone/Zones";
import LocationAreas from "@/components/Zones/LocationArea/LocationAreas";
import MFCs from "@/components/Zones/MFC/MFCs";

import * as actions from "@/store/action-types";

export default {
  name: "Zone",
  components: {
    Zones,
    LocationAreas,
    MFCs
  },
  data() {
    return {
      tabMenu: "zones",
      clearSearchField: false,
    };
  },
  computed: {
    title() {
      switch (this.tabMenu) {
        case 'zones':
          return 'Zones';
        case 'locationareas':
          return 'Location Areas';
        case 'mfc':
          return 'Micro Fulfillment Centers';
        default:
          return '';
      }
    }
  },
  watch: {
    tabMenu() {
      this.clearSearchField = true;
    },
  },
  created() {
    this.$store.dispatch(actions.GET_ZONES, 1);
    this.$store.dispatch(actions.GET_ZONES_LIST);
    this.$store.dispatch(actions.GET_LOCATION_AREAS, 1);
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
    this.$store.dispatch(actions.GET_MICROFULFILLMENT_CENTRES, 1);
    this.$store.dispatch(actions.GET_MICROFULFILLMENT_LIST);
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
