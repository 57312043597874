<template>
  <el-dialog
    :title="title"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
    append-to-body
  >
    <el-form :model="form" label-position="top" ref="zoneForm">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Zone" prop="cluster_name">
            <el-input type="text" v-model="form.cluster_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Priority" prop="priority">
            <el-select v-model="form.priority" placeholder="Select priority">
              <el-option v-for="i in 5" :key="i" :label="i" :value="i" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Location areas" prop="location_area_id">
        <el-select
          v-model="form.location_area_id"
          filterable
          multiple
          placeholder="Select location areas"
        >
          <el-option
            v-for="(location, index) in locationareas"
            :key="index"
            :label="location.name"
            :value="location.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        v-if="action === 'add'"
        type="primary"
        :loading="adding"
        @click="addZone"
        >Add</el-button
      >
      <el-button v-else type="primary" :loading="updating" @click="updateZone"
        >Save changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import zones from "@/requests/zones";

export default {
  name: "ZoneForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    show: {
      type: Boolean,
      default: false,
    },
    zone: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        cluster_name: "",
        priority: "",
        location_area_id: [],
      },
      adding: false,
      updating: false,
    };
  },
  computed: {
    title() {
      return this.action === "add" ? "Add new Zone" : "Edit Zone";
    },
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        this.form.cluster_id = this.zone.id;
        this.form.cluster_name = this.zone.cluster;
        this.form.priority = this.zone.priority;
        this.form.location_area_id = this.zone.location_area_ids || [];
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      (this.form = {
        cluster_name: "",
        priority: "",
        location_area_id: [],
      }),
        this.$refs.zoneForm.resetFields();
    },
    addZone() {
      this.$refs.zoneForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        zones
          .add(this.form)
          .then((response) => {
            if (response.data.status) {
              this.adding = false;
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.adding = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
    updateZone() {
      this.$refs.zoneForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;

        const payload = JSON.parse(JSON.stringify(this.form));
        zones
          .update(payload)
          .then((response) => {
            if (response.data.status) {
              this.updating = false;
              this.$message.success(response.data.message);
              this.$emit("success", payload);
              this.closeEvent();
            }
          })
          .catch((error) => {
            this.updating = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
