<template>
  <div>
    <eden-table-actions :title="title" @search="searchZones">
      <template
        slot="actions"
        v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
      >
        <!-- <MFCFilter :clear="filterParams.clear" @filter="applyFilter" /> -->
        <el-button type="primary" class="ml-10" @click="addMFC"
          >Add new MFC</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="fetching" type="table-with-tabs" :tabs="1" />
    <div v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <el-table :data="pageData" empty-text="No zones!">
        <el-table-column prop="zone">
          <template slot="header">
            <span class="eden-table-header"> Name </span>
          </template>
          <template v-slot="scope">
            <p class="text-faded">{{ scope.row.name || "-" }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="locations" width="200">
          <template slot="header">
            <span class="eden-table-header">
              Location areas
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <p
              class="text-link text-cursor"
              @click="showLocationAreas(scope.row)"
            >
              {{ scope.row.location_areas_count }}
            </p>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="customers" width="200">
          <template slot="header">
            <span class="eden-table-header">
              No of Customers
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <p class="text-faded">
              {{ scope.row.total_customers }}
            </p>
          </template>
        </el-table-column> -->
        <el-table-column
          v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
          width="60"
        >
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit"
                  :command="{
                    action: 'edit',
                    row: scope.row,
                    index: scope.$index,
                  }"
                  >Edit zone</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    row: scope.row,
                    index: scope.$index,
                  }"
                  >Delete zone</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="showPagination"
        :from="centerFrom"
        :to="centerTo"
        :total="total"
        :current-page.sync="page"
      />
    </div>
    <MFCLocationAreas :show.sync="showZoneLocationAreas" :zone="fulfillmentCenter" />
    <MFCForm
      :action="action"
      :show.sync="showZoneForm"
      :zone="fulfillmentCenter"
      @success="updateFulfillment"
    />
    <MFCDelete
      :show.sync="showZoneDelete"
      :zone="fulfillmentCenter"
      @success="removeFulfillment"
    />
  </div>
</template>

<script>
import MFCLocationAreas from "@/components/Zones/MFC/MFCLocationAreas";
import MFCForm from "@/components/Zones/MFC/MFCForm";
import MFCDelete from "@/components/Zones/MFC/MFCDelete";

// import MFCFilter from "@/components/Zones/MFC/MFCFilter";

import fulfillment from "@/requests/zones/mfc";
import * as actions from "@/store/action-types";

export default {
  name: "MFCs",
  components: {
    MFCLocationAreas,
    MFCDelete,
    MFCForm,
    // MFCFilter,
  },
  data() {
    return {
      fetching: false,
      page: 1,
      pageData: [],
      showPagination: false,
      showFilters: false,
      showZoneLocationAreas: false,
      showZoneForm: false,
      showZoneDelete: false,
      fulfillmentCenter: {},
      total: "",
      filterItems: {},
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      action: "add",
    };
  },
  computed: {
    fulfillmentCenters() {
      return this.$store.getters.micro_fulfillment_centers;
    },
    centerFrom() {
      return this.fulfillmentCenters.pages[this.page].from;
    },
    centerTo() {
      return this.fulfillmentCenters.pages[this.page].to;
    },
    centerTotal() {
      return this.fulfillmentCenters.total;
    },
    title() {
      return `${this.total} MFCs`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.fulfillmentCenters.pages[this.page];
      if (pageFetched) {
        this.setCurrentPageData();
      } else {
        this.getFulfillmentCenters();
      }
    },
  },
  created() {
    const pageFetched = this.$store.getters.micro_fulfillment_centers.pages[this.page];
    if (pageFetched) {
      this.setCurrentPageData();
    } else {
      this.getFulfillmentCenters();
    }
  },
  methods: {
    setCurrentPageData() {
      this.pageData = this.fulfillmentCenters.pages[this.page].data;
      this.total = this.centerTotal;
      this.showPagination = true;
      this.showFilters = false;
    },
    addMFC() {
      this.showZoneForm = true;
      this.action = "add";
      this.fulfillmentCenter = {};
    },
    getFulfillmentCenters() {
      this.fetching = true;
      this.$store
        .dispatch(actions.GET_MICROFULFILLMENT_CENTRES, this.page)
        .then(() => {
          this.setCurrentPageData();
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchZones(query) {
      if (query === "") {
        this.setCurrentPageData();
        return;
      }
      this.fetching = true;
      fulfillment
        .search(query)
        .then((response) => {
          this.pageData = this.sortList(response.data.data, "name");
          this.total = this.pageData.length;
          this.showPagination = false;
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.setCurrentPageData();
        });
    },
    showLocationAreas(zone) {
      this.fulfillmentCenter = { ...zone };
      this.showZoneLocationAreas = true;
    },
    command(command) {
      this.fulfillmentCenter = {};
      this.fulfillmentCenter = { ...command.row, index: command.index };
      if (command.action === "delete") {
        this.showZoneDelete = true;
      } else {
        this.showZoneForm = true;
        this.action = "edit";
      }
    },
    updateFulfillment(updatedZone) {
      if (updatedZone) {
        const fulfillmentCenter = this.pageData[this.fulfillmentCenter.index];
        fulfillmentCenter.name = updatedZone.name;
        fulfillmentCenter.location_areas_count = updatedZone.location_area_ids.length;
      } else {
        this.getFulfillmentCenters();
      }
    },
    removeFulfillment() {
      this.pageData.splice(this.fulfillmentCenter.index, 1);
      this.total -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  color: #2b50ed !important;
}
</style>
