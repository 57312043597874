<template>
  <div>
    <eden-table-actions :title="title" @search="searchLocationAreas">
      <template
        slot="actions"
        v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
      >
        <el-button type="primary" class="ml-10" @click="addLocationArea"
          >Add new Location Area</el-button
        >
      </template>
    </eden-table-actions>
    <eden-loader v-if="fetching" type="table" />
    <template v-else>
      <el-table :data="pageData" empty-text="No location!">
        <el-table-column prop="name" width="300">
          <template slot="header">
            <span class="eden-table-header">
              Location area
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <p v-if="scope.row.cluster" class="text-black">
              {{ scope.row.location_area }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="cluster">
          <template slot="header">
            <span class="eden-table-header">
              Zone
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <p v-if="scope.row.cluster" class="text-black">
              {{ scope.row.cluster }}
            </p>
            <p v-else class="text-faded">No cluster</p>
          </template>
        </el-table-column>
        <el-table-column prop="gardener" width="300">
          <template slot="header">
            <span class="eden-table-header">
              Gardener in charge
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template v-slot="scope">
            <p
              v-if="scope.row.gardener && scope.row.gardener.gardener_name"
              class="text-black"
            >
              {{ scope.row.gardener.gardener_name }}
            </p>
            <p v-else class="text-faded">No gardener assigned</p>
          </template>
        </el-table-column>
        <el-table-column
          v-if="allowAccessFor(['superadmin', 'admin', 'operations'])"
          width="60"
        >
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit"
                  :command="{
                    action: 'edit',
                    row: scope.row,
                    index: scope.$index,
                  }"
                  >Edit Location area</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    row: scope.row,
                    index: scope.$index,
                  }"
                  >Delete Location area</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="showPagination"
        :from="locationAreasFrom"
        :to="locationAreasTo"
        :total="locationAreasTotal"
        :current-page.sync="page"
      />
    </template>
    <location-area-form
      :action="action"
      :show.sync="showLocationAreaForm"
      :locationarea="locationarea"
      @success="updateLocationArea"
    />
    <location-area-delete
      :show.sync="showLocationAreaDelete"
      :locationarea="locationarea"
      @success="removeLocationArea"
    />
  </div>
</template>

<script>
import LocationAreaDelete from "@/components/Zones/LocationArea/LocationAreaDelete";

import * as actions from "@/store/action-types";
import locationareas from "@/requests/zones/locationareas";
import LocationAreaForm from "@/components/Zones/LocationArea/LocationAreaForm";

export default {
  name: "LocationAreas",
  components: {
    LocationAreaForm,
    LocationAreaDelete,
  },
  data() {
    return {
      fetching: false,
      showFilters: false,
      page: 1,
      pageData: [],
      showPagination: true,
      showLocationAreaForm: false,
      showLocationAreaDelete: false,
      locationarea: {},
      total: "",
      action: "",
    };
  },
  computed: {
    title() {
      return `${this.total} Location Areas`;
    },
    locationAreas() {
      return this.$store.getters.location_areas;
    },
    locationAreasFrom() {
      return this.locationAreas.pages[this.page].from;
    },
    locationAreasTo() {
      return this.locationAreas.pages[this.page].to;
    },
    locationAreasTotal() {
      return this.locationAreas.total;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.locationAreas.pages[this.page];
      if (pageFetched) {
        this.setCurrentPageData();
      } else {
        this.getLocationAreas();
      }
    },
  },
  created() {
    const pageFetched = this.$store.getters.location_areas.pages[this.page];
    if (pageFetched) {
      this.setCurrentPageData();
    } else {
      this.getLocationAreas();
    }
  },
  methods: {
    setCurrentPageData() {
      this.pageData = this.locationAreas.pages[this.page].data;
      this.total = this.locationAreasTotal;
      this.showPagination = true;
      this.showFilters = false;
    },
    getLocationAreas() {
      this.fetching = true;
      this.$store
        .dispatch(actions.GET_LOCATION_AREAS, this.page)
        .then(() => {
          this.setCurrentPageData();
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchLocationAreas(query) {
      if (query === "") {
        this.setCurrentPageData();
        return;
      }
      this.fetching = true;
      locationareas
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.total = this.pageData.length;
          this.showPagination = false;
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.setCurrentPageData();
        });
    },
    command(command) {
      this.locationarea = { ...command.row, index: command.index };
      if (command.action === "delete") {
        this.showLocationAreaDelete = true;
      } else {
        this.showLocationAreaForm = true;
        this.action = "edit";
      }
    },
    addLocationArea() {
      this.action = "add";
      this.locationarea = {};
      this.showLocationAreaForm = true;
    },
    updateLocationArea() {
      this.getLocationAreas();
    },
    removeLocationArea() {
      this.pageData.splice(this.locationarea.index, 1);
      this.total -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-more {
  transform: rotate(90deg);
  font-size: 1rem;
  color: #798b83;
}
</style>
